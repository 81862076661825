import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from './js/axioshttps'
import Vant from 'vant';
import 'vant/lib/index.css';
import './css/style.css' /*引入公共样式*/
import {isWeiXin, isMobile} from './js/common'
Vue.config.productionTip = false
Vue.use(Vant);
new Vue({
    router,
    axios,
    render: h => h(App)
}).$mount('#app')
router.beforeResolve((to, from, next)=>{
    var path = to.path;
    if (isWeiXin() || isMobile()) {
        if (path === '/') {
            next({ path: '/login/mobile'})
        } else {
            next();
        }
    } else {
        if (path === '/') {
            next({ path: '/login/pc'});
        } else {
            next();
        }
    }
    let web_title = localStorage.getItem('web_title');
    if (to.meta.title) {
        localStorage.setItem('web_title', to.meta.title);
        document.title = to.meta.title;
    } else {
        document.title = web_title;
    }
    next();
})