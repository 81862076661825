import Vue from 'vue';
import axios from 'axios';
import { Toast } from 'vant';
import router from './../router';
import qs from 'qs';
Toast.setDefaultOptions('loading', { duration: 200 });
/**
 * $post方法,接口使用axios.defaults.baseURL
 * $post2法,接口使用distributionUrl
 */
let CancelToken = axios.CancelToken;//取消请求
axios.defaults.baseURL = 'https://thlive.thape.com.cn/api/';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';//POST请求额外设置
axios.defaults.timeout = 120000;
let requestloading;
//============================================分割线=====================================================

/**
 * 开始请求设置，发起拦截处理
 * config代表发起请求的参数实体
 * 限制快速点击,得到参数中的requestname字段，用于决定下次发起请求，取消相应的相同字段的请求
 */
axios.interceptors.request.use(config => {
    if (config.method == 'post') {
        config.data = qs.stringify(config.data);
    }
    let requestName = config.method === 'post' ? config.data.requestName : config.params.requestName;//手动传递参数requestName
    if (requestName) {//判断，如果这里拿到上一次的requestName，就取消上一次的请求
        if (axios[requestName] && axios[requestName].cancel) {
            axios[requestName].cancel()
        }
        config.cancelToken = new CancelToken(c => {
            axios[requestName] = {}
            axios[requestName].cancel = c
        })
    }
    return config
}, error => {
    return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    if (requestloading) {
        requestloading.close();
    }
    if (response.data.code == 1) {
        return response;
    } else {
        Toast({duration:3000,message:response.data.msg});
        // return false;
        return Promise.reject(response);
    }
}, function (error) {
    // console.log(error.response);
    // 对响应错误做点什么
    if (requestloading) {
        requestloading.close();
    }
    if (error && error.response) {
        switch (error.response.status) {
            case 400:
                error.message = '错误请求'
                break
            case 401:
                // localStorage.removeItem('token');
                // if (document.getElementsByClassName('el-message').length === 0) {
                //     Toast(error.response.data.msg);
                // }
                // router.push({
                //     path: '/login/wechat'
                // })
                return false;
                // error.message = '未授权，请重新登录'
                // break
            case 403:
                error.message = '拒绝访问'
                break
            case 404:
                error.message = '请求错误,未找到该资源'
                break
            case 405:
                error.message = '请求方法未允许'
                break
            case 408:
                error.message = '请求超时'
                break
            case 500:
                error.message = '服务器端出错'
                break
            case 501:
                error.message = '网络未实现'
                break
            case 502:
                error.message = '网络错误'
                break
            case 503:
                error.message = '服务不可用'
                break
            case 504:
                error.message = '网络超时'
                break
            case 505:
                error.message = 'http版本不支持该请求'
                break
            default:
                error.message = `连接错误${error.response.code}`
        }
    } else {
        error.message = '链接服务器失败'
    }
    return Promise.reject(error);
});

//将axios的post方法绑定到vue的实例上面
Vue.prototype.$post = function(url, parmas, loadingMsg) {
    // let token = localStorage.getItem('token');
    // if (token) parmas.token = token;
    parmas.timestampe = new Date().getTime();
    if (loadingMsg || loadingMsg == "") {
        // 判断是否显示loading
        Toast.loading({
          message: loadingMsg,
          forbidClick: true,
        });
        // requestloading = Loading.service({
        //     lock: true,
        //     text: loadingMsg,
        //     spinner: 'el-icon-loading',
        //     background: 'rgba(0, 0, 0, 0.01)'
        // });
    }
    return new Promise((resolve, reject) => {
        axios.post(url, parmas).then(res => {
            if (!res) {
                return;
            }
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
//将axios的get方法绑定到vue的实力上面
Vue.prototype.$get = function(url, parmas, loadingMsg) {
    // let token = localStorage.getItem('token');
    // if (token) parmas.token = token;
    parmas.timestampe = new Date().getTime();
    if (loadingMsg || loadingMsg == "") {
        // 判断是否显示loading
        Toast.loading({
          message: loadingMsg,
          forbidClick: true,
        });
        // requestloading = Loading.service({
        //     lock: true,
        //     text: loadingMsg,
        //     spinner: 'el-icon-loading',
        //     background: 'rgba(0, 0, 0, 0.01)'
        // });
    }
    return new Promise((resolve, reject) => {
        axios.get(url, { params: parmas })
            .then(res => {
                if (!res) {
                    return;
                }
                resolve(res)
            }).catch(err => {
                reject(err)
            })
    })
}
export default axios;