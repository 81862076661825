<template>
    <div id="app">
        <router-view :key="$route.fullPath" v-if="isRouterAlive"/>
    </div>
</template>
<script>
export default{
    provide(){//注入依赖
        return{
            reload:this.reload,
        }
    },
    data(){
        return{
            isRouterAlive:true,
        }
    },
    created(){

    },
    methods:{

    }
}
</script>

