import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
export default new Router({
    mode: 'history',
    // mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('./views/index/index.vue'),
            meta:{
                title:'首页',
            }
        },
        {
            path: '/login/mobile',
            name: 'mobile',
            component: () => import('./views/login/mobile.vue'),
            meta:{
                title:'移动端登录',
            }
        },
        {
            path: '/login/pc',
            name: 'pc',
            component: () => import('./views/login/pc.vue'),
            meta:{
                title:'PC端登录',
            }
        },
        {
            path: '/login/code',
            name: 'code',
            component: () => import('./views/login/code.vue'),
            meta:{
                title:'验证登录',
            }
        },
        {
            path: '/s/:code',
            name: 'Live',
            component: () => import('./views/index/live.vue'),
            meta:{
                title:'2024天华年会',
            }
        }
    ]
})